import React from "react";
import "../Home/Homepage.css";
import Footer from "../../layout/footer/Footer";

function Product() {
  return (
    <>
      <div className="background-productPage bg-cover p-10">
        <div className="product-Image">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/LogoBitsoft/iconproduct.png"
            alt=""
          />
        </div>
        <div className="product-Image1">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/1.jpg"
            alt=""
          />
        </div>
        <div className="product-Image1">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/2.jpg"
            alt=""
          />
        </div>
        <div className="product-Image1">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/3.jpg"
            alt=""
          />
        </div>
        <div className="product-Image1">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/4.jpg"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Product;
