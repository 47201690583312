import React from "react";
import "./Homepage.css";
import "animate.css";
function Homepage() {
  return (
    <div className="background-p1 bg-fixed sm:bg-cover md:bg-cover bg-cover xl:w-full  md:h-[25rem] h-[20rem] xl:relative xl:top-20 relative top-[3.75rem] ">
      <div className="">
        <p class="centered-text animate__backInLeft">
          Accelerating Thailand's <br></br>development to Industry 4.0
        </p>
      </div>
    </div>
  );
}

export default Homepage;
