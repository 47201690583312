import React from "react";
import "../Home/Homepage.css";
import NewTeam from "./setTeam/NewTeam";
import Footer from "../../layout/footer/Footer";
function About() {
  return (
    <>
      <div className="background-p5-about xl:w-full w-full xl:h-full xl:p-16 p-5 bg-no-repeat ">
        <div className="grid grid-cols-2 container gap-5 mx-auto xl:relative xl:top-16 relative mt-20">
          <div className="text-header">
            <p className="text-detail">
              We are <span className="text-detail">BITSOFT</span>
            </p>
            <p className="text-header">
              Company Name : &nbsp;&nbsp;
              <span className="text-detail">
                Bunnag Industrial Technologies and Software Co., Ltd.
              </span>
            </p>
            <p className="text-header">
              Business Type : &nbsp;&nbsp;
              <span className="text-detail">Software Compony </span>
            </p>
            <p className="text-header">
              Registered date :{" "}
              <span className="text-detail">February 1st, 2020</span>
            </p>
            <p className="text-header">
              Address :{" "}
              <span className="text-detail">
                10/1 Soi Ramindra 117, Yeak 2 ,Ramintra Rd.,Minburi, Bangkok
              </span>
            </p>
            <p className="text-header">
              Engineers : <span className="text-detail">8 persons</span>
            </p>
            <p className="text-header">
              Website :{" "}
              <span className="text-detail">https://www.bitsoft.app</span>
            </p>
            <p className="text-header">
              Clients :{" "}
              <span className="text-detail">
                Kyoseki Automotive Piping Co.,LTD. <br></br>Asian Asphalt Co.,
                Ltd.
                <br></br>Sahaporntool Ltd., Part.
              </span>
            </p>
            <p className="text-header">
              Partner : <span className="text-detail">PTT RAISE</span>
            </p>
          </div>
          <div className="">
            <img
              src="https://bitsoft-image-video.s3.ap-southeast-1.amazonaws.com/img-bitsoft/%E0%B9%84%E0%B8%9F%E0%B8%A5%E0%B9%8C%E0%B8%A0%E0%B8%B2%E0%B8%9E%E0%B8%AA%E0%B8%B3%E0%B8%AB%E0%B8%A3%E0%B8%B1%E0%B8%9A+bitsoft/Developer+activity-amico.png"
              className="mx-auto"
              alt=""
            />
          </div>
        </div>
        <div className="product-Image1 mt-6">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/about-AWS.jpg"
            alt=""
          />
        </div>
        <div className="product-Image1 mt-5">
          <img
            className=""
            src="https://bitsoft-image-videos.s3.ap-southeast-1.amazonaws.com/image-in-web/bg-cover/PartProject.jpg"
            alt=""
          />
        </div>
        <div className="">
          <h3 className="text-[#F9CC07] text-[3rem] p-5 text-center">
            BITSOFT Team
          </h3>
          <NewTeam />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
